
import { useState, useEffect, useContext } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { components } from "react-select";
import axios from "axios";
import pfContext from '../../context/PfContext';
import { useAuth } from "../../GlobalComponent/Authentication/AuthProvider";

export function FilterDropDown1(props) {
  const { user } = useAuth();
  const context = useContext(pfContext);
  const cwid = (user !== null && user.user_cwid !== null) ? user.user_cwid : "";
  const endpoint = process.env.REACT_APP_ENDPOINT;
  const key = process.env.REACT_APP_API_KEY;
  const [allOptions, setAllOptions] = useState([]);
  const [dataSet, setDataSet] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(props.isAllSelected);
  const [unCheckedValue, setUnCheckedValue] = useState([]);
  const [defaultColor, setDefaultColor] = useState("white");
  const isPrevSce = props.isPrevSceLoad;
  const [savedFilterValue, setSavedFilterValue] = useState(props.value);

  const [searchKey, setSearchKey] = useState("");


  useEffect(() => {
    if (props.defaultValue !== null) {

      setIsAllSelected(props.isAllSelected);
      props.setSelectedOptions(props.defaultValue);
      setDefaultColor("lightyellow");

    }
  }, [props.defaultValue]);


  useEffect(() => {

    setIsAllSelected(props.isAllSelected);

  }, [props.isAllSelected]);



  useEffect(() => {
    if (allOptions.length === 0) {
      setDefaultColor("white");
    }
  }, [allOptions])

  useEffect(() => {
    if (props.selectedOptions?.length == 0) {
      setDefaultColor("white");
    }
    else {
      setDefaultColor("lightyellow");
    }
  }, [props.selectedOptions])


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '5px',
      border: '2px solid #ccc',
      boxShadow: state.isFocused ? '0 0 0 2px #3699FF' : null,
      height: "30px",
      backgroundColor: defaultColor,
      width: "18rem"
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#3699FF' : null,
      color: state.isFocused ? 'white' : null,
      width: "22rem"

    }),
    container: (provided, state) => ({
      ...provided,

      width: "22rem"
    })
  }


  function getData(data, type, page) {
    const jsonArray = [];
    const data1 = data.data.data[`get${type}Multi`][`${type}_MasterData`];
    if (data1 !== null && data1.length > 0) {
      if (page === 1) {
        jsonArray.push({ code: "All", name: "select all", isChecked: false });

      }
      for (let i = 0; i < data1.length; i++) {
        const string1 = data1[i].replace("[", "").replace("]", "");
        const code = string1.split(",")[0];
        const name = string1.split(",")[1];
        if (name !== null)
          jsonArray.push({ code: code, name: `${code}-${name}`, isChecked: false });
      }
    }

    return jsonArray;

  }



  const getSubQuery = (type, searchQuery) => {

    let gsmCode = (context.gsmNetwork)?.map((item) => (item.code));
    let locCode = (context.location)?.map((item) => (item.code));
    let comCode = (context.com)?.map((item) => (item.code));
    let proCode = (context.product)?.map((item) => (item.code));
    let pipCode = (context.pipeline)?.map((item) => (item.code));


    let subQuery = "";
    if (type === "Brand")
      subQuery = `,CoM_Sel_All:${context.isCoMSelectAll},GSM_Sel_All:${context.isGsmSelectAll},Location_Sel_All:${context.isLocationSelectAll},Product_Sel_All:${context.isProductSelectAll}, Brand_Values:"${(searchQuery === "") ? ("" + pipCode.toString() + "") : ""}", CoM_Values: "${comCode.toString()}",  GSM_Values: "${gsmCode.toString()}",Location_Values: "${locCode.toString()}", Product_Values: "${proCode.toString()}"`;
    else if (type === "GSM")
      subQuery = `,Brand_Sel_All:${context.isBrandSelectAll},CoM_Sel_All:${context.isCoMSelectAll},Location_Sel_All:${context.isLocationSelectAll},Product_Sel_All:${context.isProductSelectAll}, Brand_Values:"${pipCode.toString()}", CoM_Values: "${comCode.toString()}",  GSM_Values: "${(searchQuery === "") ? ("" + gsmCode.toString() + "") : ""}", Location_Values: "${locCode.toString()}", Product_Values: "${proCode.toString()}"`;
    else if (type === "CoM")
      subQuery = `, Brand_Sel_All:${context.isBrandSelectAll},GSM_Sel_All:${context.isGsmSelectAll},Location_Sel_All:${context.isLocationSelectAll},Product_Sel_All:${context.isProductSelectAll},Brand_Values:"${pipCode.toString()}", CoM_Values: "${(searchQuery === "") ? ("" + comCode.toString() + "") : ""}",  GSM_Values: "${gsmCode.toString()}",Location_Values: "${locCode.toString()}", Product_Values: "${proCode.toString()}"`;
    else if (type === "Location")
      subQuery = `,Brand_Sel_All:${context.isBrandSelectAll},CoM_Sel_All:${context.isCoMSelectAll},GSM_Sel_All:${context.isGsmSelectAll},Product_Sel_All:${context.isProductSelectAll}, Brand_Values:"${pipCode.toString()}", CoM_Values: "${comCode.toString()}",  GSM_Values: "${gsmCode.toString()}",Location_Values: "${(searchQuery === "") ? ("" + locCode.toString() + "") : ""}", Product_Values: "${proCode.toString()}"`;
    else if (type === "Product")
      subQuery = `, Brand_Sel_All:${context.isBrandSelectAll},CoM_Sel_All:${context.isCoMSelectAll},GSM_Sel_All:${context.isGsmSelectAll},Location_Sel_All:${context.isLocationSelectAll},Brand_Values:"${pipCode.toString()}", CoM_Values: "${comCode.toString()}",  GSM_Values: "${gsmCode.toString()}",Location_Values: "${locCode.toString()}", Product_Values: "${(searchQuery === "") ? ("" + proCode.toString() + "") : ""}"`;

    return subQuery;
  }
  const loadOptions = async (searchQuery, loadedOptions, { page, type, updateOptions
  }) => {

    let offset = 0;

    if (page > 1) offset = page * 10 - 10;

    const removeLeadingZeros = (name) => {
      return name.replace(/^0+/, '');
    }

    const response = await axios({
      url: endpoint,
      method: "POST",
      headers: { "x-api-key": key },

      data: {
        query: `query MyQuery {
    get${type}Multi(limit: 10, search_term: "${searchQuery}",CWID:"${cwid}",offset: ${offset},
    ${type}_Sel_All:${isAllSelected},
     ${getSubQuery(type, searchQuery)}
   ) {
      No_Of_Pages
      ${type}_MasterData
    }
  }
  `,
      },
    });

    let responseJSON = getData(response, type, page);

    responseJSON = responseJSON.map(item => {
      if (type === "Product") {
        return {
          ...item,
          name: removeLeadingZeros(item.name),
        };
      } else {
        return item;
      }
    });

    updateOptions(responseJSON, page, type);

    return {
      options: responseJSON,
      hasMore: responseJSON.length >= 1,
      additional: {
        page: searchQuery ? 2 : page + 1,
        type: type,
        updateOptions: updateOptions
      },
    };

  };



  useEffect(() => {
    if (dataSet) {
      const total = [...allOptions, ...dataSet];

      const ids = total.map(({ code }) => code);
      const filtered = total.filter(({ code }, index) =>
        !ids.includes(code, index + 1));

      setAllOptions(filtered);

    }

  }, [dataSet]);

  const updateOptions = (items, page, type) => {
    if (page === 1 && props.isAllSelected && !props.isPrevSceLoad) {
      setUnCheckedValue([]);
      !props.defaultValue &&
        props.setDefaultValue(
          items.find((o) => o.code === props.defaultOptionValue)
        );
    } else if (!props.isAllSelected && props.isPrevSceLoad) {
      setSavedFilterValue(props.value);
    }

    setDataSet(items);

    props.updateSteps(type, "select-option");
  };



  const onChange = (value, event) => {
    if (event.action === "select-option" && event.option.code === "All") {
      setUnCheckedValue([]);
      setIsAllSelected(true);
      props.setIsAllSelected(true);
      props.onChange([]);
      props.setDefaultValue({
        code: "All",
        name: "select all",
        isChecked: false,
      });

      setDefaultColor("lightYellow");
    } else if (
      event.action === "deselect-option" &&
      event.option.code === "All"
    ) {
      props.setSelectedOptions([]);
      setIsAllSelected(false);
      props.setIsAllSelected(false);
      props.onChange([]);
      props.setDefaultValue(null);
      setUnCheckedValue([]);
      setDefaultColor("White");
    } else if (event.action === "deselect-option") {
      const deSelect = [...unCheckedValue, event.option.name];

      setUnCheckedValue(deSelect);

      props.setSelectedOptions(value.filter((o) => o.value !== "All"));
      if (isAllSelected) {
        //console.log("[...props.value,event.option]",[...props.value,event.option])
        props.onChange([...props.value, event.option]);
      } else {
        let newValue = props.value?.filter((o) => o.code !== event.option.code);
        props.onChange(newValue);
      }
      setDefaultColor("lightYellow");
    } else if (event.action === "select-option" && event.option.isChecked) {
      event.option.isChecked = false;
      const deselect = [...unCheckedValue, event.option.name];
      setUnCheckedValue([...unCheckedValue, event.option.name]);

      props.onChange([...props.value, event.option]);
    } else if (event.action === "clear") {
      props.setSelectedOptions([]);
      //clicking the "x" should clear the field and check "select all"
      setIsAllSelected(true);
      props.setIsAllSelected(true);
      props.onChange([]);
      setUnCheckedValue([]);
      props.setDefaultValue(null);
      setDefaultColor("White");
      setSearchKey("");
    } else {
      if (!isAllSelected) {
        setUnCheckedValue([]);
        props.setSelectedOptions(value);
        props.onChange(value);
        setIsAllSelected(false);
        props.setIsAllSelected(false);
      } else {
        const filtervale = unCheckedValue.filter(
          (o) => o !== event.option.name
        );
        setUnCheckedValue(filtervale);
        props.setSelectedOptions(filtervale);
        props.onChange(
          filtervale.map((item) => ({
            code: item.split("-")[0],
            name: item,
            isChecked: false,
          }))
        );

      }

      setDefaultColor("lightyellow");
    }
    props.updateSteps(props.type, event.action);
  };

  const handleInputChanged = (input, reason) => {
    if(reason.action === "input-change" ){
      setSearchKey(input);
    }else if (
      reason.action === "input-blur" ||
      reason.action === "menu-close"
    ) {
      setSearchKey("");
      return;
    }

  }

  const Option = (props) => {

    const isOptionUnchecked = unCheckedValue?.indexOf(props.label);
    let isChecked = (isAllSelected && isOptionUnchecked == -1) ? true : props.isSelected;

    if (isPrevSce && !isAllSelected) {
      // console.log("savedFilterValue",savedFilterValue);
      const isValueinArray = savedFilterValue?.filter((val) => {
        const code = props.label.split("-")[0];

        if (val.code == code)
          return val;

      })
      isChecked = (isValueinArray.length > 0) ? true : isChecked;

    }

    props.data.isChecked = isChecked;
    //console.log("isChecked",isChecked);
    return (

      <div>
        <components.Option {...props}>
          <input
            key={props.label}
            type="checkbox"
            checked={isChecked}
            onChange={() => null}

          />{" "}
          <label style={{ fontSize: "12px" }}>{props.label}</label>
        </components.Option>
      </div>

    )
  }
  const handleMenuOpen = () => {
    if (props.selectedOptions?.length == 0) {
      setIsAllSelected(true);
      props.setIsAllSelected(true);
      props.setDefaultValue({ code: "All", name: "select all", isChecked: false });
      // setDefaultColor("white");
    }
  }

  return (
    <div>
      <AsyncPaginate
        key={props.dependency}
        debounceTimeout={300}
        hideSelectedOptions={false}
        value={props.selectedOptions}
        loadOptions={loadOptions}
        isMulti={true}
        closeMenuOnSelect={false}
        components={{ Option }}
        controlShouldRenderValue={false}
        getOptionValue={(option) => option.name}
        getOptionLabel={(option) => option.name}
        isClearable={true}
        onChange={onChange}
        inputValue={searchKey}
        onInputChange={handleInputChanged}

        onMenuOpen={handleMenuOpen}
        // isSearchable={false}
        placeholder={props.title}
        styles={customStyles}
        additional={{
          page: 1,
          type: props.type,
          updateOptions: updateOptions
        }}
      />
    </div>
  );
}